.faq-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .faq-item {
    margin-bottom: 20px;
  }
  
  .faq-question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
    transition: all 1ms ease-in-out;

  }
  
  .faq-question span {
    font-weight: bold;
  }
  
  .faq-answer {
    padding: 10px 20px;
    background-color: #fff;
    transition: all 1ms ease-in-out;
   
  }  